// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import Pagination from "https://framerusercontent.com/modules/L6zHqqMB9weqTlE8uMAv/8lXBVOoPSyjs336TGm7d/dotDxqtbY.js";
const PaginationFonts = getFonts(Pagination);

const cycleOrder = ["qmBjPM47v", "XVeVghmAT", "hN0qLHJwI"];

const variantClassNames = {hN0qLHJwI: "framer-v-6rx7dr", qmBjPM47v: "framer-v-16cu3rw", XVeVghmAT: "framer-v-kk0nql"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Page 1": "qmBjPM47v", "Page 2": "XVeVghmAT", "Page 3": "hN0qLHJwI"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "qmBjPM47v", tap: ItYajHZrn, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "qmBjPM47v", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap5ybxv6 = activeVariantCallback(async (...args) => {
if (ItYajHZrn) {
const res = await ItYajHZrn(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-iATN0", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-16cu3rw", className)} data-framer-name={"Page 1"} data-highlight layoutDependency={layoutDependency} layoutId={"qmBjPM47v"} onTap={onTap5ybxv6} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({hN0qLHJwI: {"data-framer-name": "Page 3"}, XVeVghmAT: {"data-framer-name": "Page 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-ndpqx6-container"} layoutDependency={layoutDependency} layoutId={"p8zydAQaV-container"} transition={transition}><Pagination height={"100%"} id={"p8zydAQaV"} layoutId={"p8zydAQaV"} page={"1"} style={{width: "100%"}} variant={"kUfDJTefJ"} width={"100%"} {...addPropertyOverrides({hN0qLHJwI: {variant: "D2Y18Kse0"}, XVeVghmAT: {variant: "D2Y18Kse0"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-s9recp-container"} layoutDependency={layoutDependency} layoutId={"rrgmaLD9B-container"} transition={transition}><Pagination height={"100%"} id={"rrgmaLD9B"} layoutId={"rrgmaLD9B"} page={"2"} style={{width: "100%"}} variant={"D2Y18Kse0"} width={"100%"} {...addPropertyOverrides({XVeVghmAT: {variant: "kUfDJTefJ"}}, baseVariant, gestureVariant)}/></motion.div><motion.div className={"framer-101uf4s-container"} layoutDependency={layoutDependency} layoutId={"fx9oJDu7o-container"} transition={transition}><Pagination height={"100%"} id={"fx9oJDu7o"} layoutId={"fx9oJDu7o"} page={"3"} style={{width: "100%"}} variant={"D2Y18Kse0"} width={"100%"} {...addPropertyOverrides({hN0qLHJwI: {variant: "kUfDJTefJ"}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-iATN0 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-iATN0 * { box-sizing: border-box; }", ".framer-iATN0 .framer-2tjtt4 { display: block; }", ".framer-iATN0 .framer-16cu3rw { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-iATN0 .framer-ndpqx6-container, .framer-iATN0 .framer-s9recp-container, .framer-iATN0 .framer-101uf4s-container { flex: none; height: auto; position: relative; width: 40px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-iATN0 .framer-16cu3rw { gap: 0px; } .framer-iATN0 .framer-16cu3rw > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-iATN0 .framer-16cu3rw > :first-child { margin-left: 0px; } .framer-iATN0 .framer-16cu3rw > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 144
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"XVeVghmAT":{"layout":["auto","auto"]},"hN0qLHJwI":{"layout":["auto","auto"]}}}
 * @framerVariables {"ItYajHZrn":"tap"}
 */
const FramerISNFWTRaB: React.ComponentType<Props> = withCSS(Component, css, "framer-iATN0") as typeof Component;
export default FramerISNFWTRaB;

FramerISNFWTRaB.displayName = "Elements/Pagination Numbers Copy";

FramerISNFWTRaB.defaultProps = {height: 51, width: 144};

addPropertyControls(FramerISNFWTRaB, {variant: {options: ["qmBjPM47v", "XVeVghmAT", "hN0qLHJwI"], optionTitles: ["Page 1", "Page 2", "Page 3"], title: "Variant", type: ControlType.Enum}, ItYajHZrn: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerISNFWTRaB, [...PaginationFonts])